.password-wrapper {
    display: flex;
    height: 100vh;
    width: 100vw;
    position: relative;
  }

.user-text {
  //animated gradient text 
  background: linear-gradient(90deg, #3d70f4, #00e2fa, #3d70f4);
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease infinite;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @media (max-width: 800px) {
    font-size: 1.5rem;
  }

}
  
  .login-form {
    width: 50%;
    padding: 2rem;
    margin: auto;
    
    .login-smaller {
      width: 60%; /* Changed to 100% */
      text-align: left;
      margin: auto;
      @media (max-width: 800px) {
        width: 90%;
      }
    }
    @media (max-width: 800px) {
        width: 100%;
        //center on image 
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
    }
  }
  
  .login-image {
    width: 50%;
    @media (max-width: 800px) {
        width: 100%;
    }
    .top-right-text {
        position: absolute;
        top: 0;
        right: 0;
        margin: 1rem;
        color: white;
        font-family: 'Allura', cursive;
        font-size: 2rem;
        font-weight: 700;
        @media (max-width: 800px) {
            font-size: 1rem;
        }
    }
    .login-img-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 800px) {
        filter: brightness(.6) blur(2px);
      }
    }

  }
  
  .login-title {
    margin-top: 0;
    font-size: 35px;
    font-weight: 700;
  
    @media (max-width: 800px) {
      font-size: 25px;
      color: white;
    }
  }
  
  .login-subtitle {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 15px;
    font-weight: 500;
    color: darkgray;
  
    @media (max-width: 800px) {
      font-size: 15px;
    }
  }
  
  .form-group {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  label {
    display: block;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    @media (max-width: 800px) {
      color: white;
    }
  }
  
  .password-input {
    width: 100% !important;
    padding: 1.5rem 0.5rem;
    border-radius: 2px;
    border: 1px solid lightgray;
    height: 2rem;
    outline: none;
    font-size: 1rem !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @media (max-width: 800px) {
      font-size: 20px;
      //change preview text height
        height: 2.5rem;
    }
  }
  
  .password-button {
    background-color: #3d70f4;
    color: white;
    padding: 1.2rem 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: .9rem;
    font-weight: 700;
    @media (max-width: 800px) {
      font-size: 1rem;
    }
    transition: ease-in-out 0.2s;
    &:hover {
      transform: scale(1.02);


    }
  }
  

  .loading-client {
    //cover whole page 
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    margin: auto;
    //center content
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);

  }