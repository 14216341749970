.loader-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden;
    padding: 0;
    .header {
      position: relative;
      animation: fadeInOut 2s ease-in-out forwards;
      .loading-image {
        width: 100% !important;
        height: 100vh;
        object-fit: cover;
        padding: 0;
        -webkit-filter: brightness(0.6);
        -moz-filter: brightness(0.6);
        -o-filter: brightness(0.6);
        -ms-filter: brightness(0.6);
        filter: brightness(0.6);
        animation: fadeInOut 2s ease-in-out forwards;
      }
      .header-contents {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: auto;
        .loading-text {
            padding-top: 1rem;
            font-size: 4vmax;
            text-align: center;
            text-transform: uppercase;
            background: linear-gradient(-225deg, #3D70F4 0%, #3D70F4 29%, #45C4B0 67%, #9AEBA3 100%);
            background-size: 200% 200%;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: Gradient 2s ease infinite;
            display: inline-block;
        
            @media (max-width: 600px) {
              font-size: 3.2vmax;
            }
        }
        
        @keyframes Gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
        
  
        @keyframes textclip {
          to {
            background-position: 200% center;
          }
        }
  
        .information {
          width: 100%;
          font-size: 20px;
          text-align: left;
          font-family: 'Raleway', sans-serif;
        }
      }
    }
  }
  
  .fade {
    animation: fadein 1s;
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  
  .fade-text-out {
    opacity: 0;
  }
  
  .fade-text {
    transition: opacity 1s;
  }
  

// Add this CSS class to your SCSS file
.loading-indicator {
    display: flex;
    margin: auto;
    width: 100%;
    height: 100%;
    margin: auto !important;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;

    .MuiCircularProgress-colorInherit {
        opacity: 0.2;
        //make it glow with a gradient
        
    }
  
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

