.errorPage {
    margin-top: 20px;
    top: 50%;
    left: 50%;
    font-family: 'Overpass', sans-serif;
    .four {
        color: #3D70F4;
        font-size: 80px;
    }
    .mainError {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
    }
    .home {
        font-weight: 600;
        margin-top: 20px;
        color: white;
        padding: 15px;
        font-size: 15px;
        border-radius: 15px;
        width: 150px;
        background-color: #3D70F4;
        transition: ease-in-out .3s;
        &:hover {
            background-color: #0e59b5;
        }
    }
    .subText {
        color: darkgray;
    }
}