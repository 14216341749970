
.button {
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
     .buttonViewCollection {
         width: 100%;
            height: 100%;
         .viewCollection {
            color: white;
            padding: 10px;
            transition: ease-in-out .2s color, ease-in-out .2s border;            width: 100%;
            font-size: 20px;
            font-weight: 400 !important;
            margin-top: 2rem;
        }
     }
 }
  .carousel {
    touch-action: pan-y;
  }
  
.collectionNew {
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden;
    font-family: 'Varela', sans-serif;
    .collectionItem {
        position: relative;
        .collectionDetails {
            max-width: 450px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            color: black;
            mix-blend-mode: screen;
            font-size: 45px;
            @media screen and (max-width: 768px) {
                font-size: 30px;
            }                
            font-weight: 600;
            margin: auto;
            padding: 2%;
            width: 80%;
        }
        .nameCollection {
            text-transform: uppercase;
            text-align: center;
            bottom: 0;
            left: 0;
            padding: 1%;
            font-weight: 600;
            width: 100%;
            color: white;
            mix-blend-mode: difference; 
        }
        .collectionPicture {
            height: 100vh;
            .imageCollection {
                height: 100vh;
                object-fit:cover;
                filter: brightness(.6);
            }
        }
        
       
    }
}