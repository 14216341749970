.App {
  text-align: center;
  overflow: hidden;
  width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
    cursor: url("./assets/camera_icon.png") 64 64, default;
   
    &:active {
        cursor: url("./assets/picture_icon.png") 64 64, pointer;
    }
  
  
}

button {
  font-family: 'Overpass', sans-serif;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



hr {
  appearance: none;
}
.actionSnack {
  color: #3498db;
  font-weight: 600;
  margin-left: .5rem;
}