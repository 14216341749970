.viewLocations {
    font-size: 30px;
}
#visits {
    font-size: 25px;
    color: black;
    font-weight: 600;
}

.collectionsAdmin {
    font-size: 25px;
    color: black;
    font-weight: 600;
    color: white;
    height: 100vh;
    .headerCollections {
        font-size: 4rem;
    }
    .collectionsImage {
        width: 100%;
    height: 100vh;
        object-fit: cover;
        filter: brightness(50%);
    }
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 60%;
    margin: auto;
    text-align: center;
  }

  td, th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align:center
  }
.locationDiv {
    margin: auto;
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
}
.locationIcon {
    color: black;
    transition: ease-in-out .3s;
    cursor: pointer;
}
.locationIcon:hover {
    color: darkgray;
}
.deleteIcon {
    cursor: pointer;
    vertical-align: middle;
    margin-bottom: auto;
    margin-top: 10px;
    transition: ease-in-out .3s;
    &:hover {
        color: red;
    }
}
.checkIcon {
    cursor: pointer;
    vertical-align: middle;
    margin-bottom: auto;
    margin-top: 10px;
    margin-right: 10px;
    transition: ease-in-out .3s;
    &:hover {
        color: darkgreen;
    }
}
.rankingNav {
    margin-right: 20px;
}
.editIcon {
    cursor: pointer;
    margin-left: 10px;
    vertical-align: middle;
    margin-bottom: auto;
    margin-top: 10px;
    transition: ease-in-out .3s;
    &:hover {
        color: darkgray;
    }
}
.addMore {
    border: black 1px solid;
    font-size: 13px;
    width: 8%;
    font-weight: 600;
    height: 10%;
    border-radius: 15px;
    padding: 5px;
    font-weight: 400;
    transition: ease-in-out .3s;
    &:hover {
        background-color: lightgray;
    }
}
.signOut {
    transition: ease-in-out .2s;
    &:hover {
        color: darkred;
    }
}

.signIcon {
    margin-right: 5px;
}
.header { 
    font-size: 25px;
    font-weight: 600;
}

.adminNavigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 50%;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 20px;
    .info {
        font-family: 'Overpass', sans-serif;
        display: block;
    }
    .wrapper {
        margin: auto;
        width: fit-content;
        cursor: pointer;
        transition: ease-in-out .2s;
        &:hover {
            color: lightgray;
        }
    }
    .viewsNav {
        width: fit-content;
        flex: 10;
        display: block;
    }
    .blogNav {
        flex: 1;
        flex: 10;
        display: block;
    }
    .postNav {
        flex: 10;
    }
    .navIcon {
        font-size: 20px;
    }
}
@media screen and (max-width: 480px) {
   .info {
       font-size: 10px;
   }
}

@media screen and (max-width: 430px) {
    
      .addMore {
          font-size: 12px;
          width: 100px;
      }
      .deleteIcon {
          display: inline-block;
          margin-left: 15px;
          margin-bottom: -10px;
          margin-top: 5px;
      }
      .editIcon {
          display: inline-block;
          margin-bottom: -10px;
          vertical-align: middle;
          margin-left: 15px;
      }
 }

 .rankings {
     font-size: 25px;
     font-family: 'Varela', sans-serif;
 }
 textarea {
     font-family: 'Varela', sans-serif;
     height: 200px;
 }

 .everything {
     margin-bottom: 10px;
     cursor: pointer;
     transition: ease-in-out .2s;
     &:hover {
         color: red;
     }
 }

 .approveIcon {
     margin-top: .6rem;
     margin-right: -.5rem;
     display: inline-block;
     font-size: 20px;
     cursor: pointer;
     transition: ease-in-out .2s;
     &:hover {
         color: darkgreen;
     }
 }
 .removeIcon {
    display: inline-block;
    margin-left: 1rem;
    font-size: 20px;
    cursor: pointer;
    transition: ease-in-out .2s;
    &:hover {
        color: red;
    }
}

