.client-wrapper {
    width: 100vw;
    position: relative;
}
.confetti-success {
    z-index: 9999 !important;
    height: 100vh !important;
    width: 100%;
}
.wrapper-request {
    display: flex;
    flex-direction: row;
    gap: .3rem;
}
.extension {
    cursor: pointer;
    color: #3D70F4;
}
.client-header {
    //half left half right
    display: flex;
    height: 100vh;
    position: relative;
    //make it split half left half right 
    @media (max-width: 800px) {
       //center on image
         width: 100vw;
    }
    .client-text {
        //center in middle of image 
        margin: auto;
        text-align: center;
        z-index: 999;
        color: black;
        //half right
        width: 50%;
        @media (max-width: 800px) {
            width: 0%;
        } 
    }
    .client-smaller {
        width: 70%;
        text-align: left;
        margin: auto;
        @media (max-width: 800px) {
            justify-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 999;
        }
    }
    .client-header-img {
        //half left
        width: 50%;
        height: 100vh;
        overflow: hidden;
        @media (max-width: 800px) {
            width: 100vw;
            filter: brightness(.4) blur(2px);
        }
    }
    .client-header-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        //make it darker for visibility 
        -webkit-filter: brightness(.3) blur(.5px) contrast(1.2); //change the value according to what suits the best
        -moz-filter: brightness(.3) blur(.5px) contrast(1.2); //change the value according to what suits the best
        -o-filter: brightness(.3) blur(.5px) contrast(1.2); //change the value according to what suits the best
        -ms-filter: brightness(.3) blur(.5px) contrast(1.2); //change the value according to what suits the best
        filter: brightness(.5) blur(.5px) contrast(1.2); //change the value according to what suits the best
    }
    .client-title {
        font-size: 3rem;
        font-weight: 400;
        margin-bottom: 1rem;
        .name {
            //gradient text
            background: linear-gradient(90deg, #3D70F4, #07329e);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 700;
            border-image-slice: 1;
        }
        @media (max-width: 800px) {
            color: white;
        }
    }
    .client-header-subtitle {
        font-weight: 500;
        text-align: left;
        color: darkgray;
        width: 100%;
        @media (max-width: 800px) {
            color: white;
        }
    }
}


.actions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 2rem;
    align-items: center;
    .download-button {
        background-color: #3D70F4;
        color: white;
        padding: 1rem 2rem;
        border-radius: 5px;
        border: none;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: .5rem;
        align-items: center;
        //on hover converge with lightening transition towards center
        transition: all .2s ease-in-out;
        &:hover {
            //add box shadow 
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        }
    }
    .scroll-btn-client {
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: .5rem;
        align-items: center;
        border: solid black 1px; 
        padding: 1rem 2rem;
        //nice hover transition 
        transition: all .2s ease-in-out;
        &:hover {
            //add box shadow 
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        }
        @media (max-width: 800px) {
            color: white !important;
            border: solid white 1px; 
        }
    }
}

.image-wrapper-client {
    position: relative;
    overflow: hidden;
    height: auto;
  }
  
  .download-button-client {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity:0;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    .download-button-btn {
        color: white !important;
        transition: ease-in-out .2s !important;
        &:hover {
            color: #3D70F4 !important;
        }
       .download-icon {
        font-size: 75px !important;
        @media screen and (max-width: 800px) {
            font-size: 70px !important;
        }
       }
    }
  }
  
  .loading-download {
    //cover whole page 
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    margin: auto;
    //center content
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .loading-text {
        font-size: 1.5rem;
        font-weight: 500;
        color: white;
    }
  }
  

  
  .image-wrapper-client:hover > .download-button-client {
    display: block;
    opacity: 1;

    /* Delay before displaying the download button */
  }
  
  .client-images {
    position: relative;
    overflow: hidden;
    height: auto;
  
    .image-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0px 0px;
      overflow: hidden;
      height: fit-content;
  
      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }
  
      @media (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  
    .client-image-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: ease-in-out 0.5s;
  
      
    }
  }

  .timer {
    padding-top: 1rem;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 1rem;
    .timer-text {
        background: linear-gradient(90deg, #3D70F4, #07329e);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media (max-width: 800px) {
        font-size: 19px;
    }
  }
  #subtitle {
    font-size: 1rem;
    font-weight: 500;
    color: lightgray !important;
    text-align: center;
    @media (max-width: 800px) {
        font-size: .8rem;
        color: white !important;
    }
}



.success-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .modalBox {
    position: relative;
    width: 400px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    outline: none;
    font-family: 'Varela', sans-serif;
    
  }
  .success-modal-title {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    @media (max-width: 800px) {
        font-size: 1.2rem;
    }
}
  .checkIcon {
    font-size: 60px !important;
    color: #3D70F4;
    &:hover {
        color: #07329e !important;
    }
  }
  
  