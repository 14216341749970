.carousel.carousel-slider {
  overflow: inherit;
}

.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
  background-color: transparent;

}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
  background-color: transparent;
  display: inline-block;
  font-size: 25px !important;
  transform: scale(2);
}


.carousel .control-arrow, .carousel.carousel-slider .control-arrow:hover{
  display: inline-block;
  font-size: 25px !important;
  transform: scale(2);
  border: solid #3D70F4;

}

.carousel .control-next.control-arrow:before {
  content: '';
  margin: auto;
  color: #3D70F4 !important;
}

.descriptionCollection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  @media screen and (max-width: 500px ) {
    font-size: 20px;
    width: 100%;
    top: 80%;
  }
  text-align: center;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  .state-item {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: center;
    align-items: center;
  }
  .swipe-icon {
    font-size: 50px !important;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: relative;
  animation: hover-effect 1s ease-in-out infinite alternate;
  @media screen and (max-width: 400px ) {
    font-size: 35px !important;
  }
  }
  
  .scroll-icon {
    font-size: 50px !important;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    animation-name: bounce;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
    animation-iteration-count: infinite;
    animation-direction: alternate;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    @media screen and (max-width: 400px ) {
      font-size: 35px !important;
    }
  }

  @keyframes hover-effect {
    0% {
      left: -10px;
    }
    100% {
      left: 10px;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
}
.alert-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  @media screen and (max-width: 600px) {
    text-align: left;
    
  }
}

.scrollTop {
  border: 2px solid black;
  color: black !important;
}

.alert {
  z-index: 9999;
  width: 100%;
  position: fixed;
}

.viewBrowser {
  padding-top: .5rem;
  text-decoration: underline;
}
.gallery-main-wrapper {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
   // padding-bottom: 2rem;
}

  .galleryHeading {
    position: relative;
    .headingImage {
      position: relative;
      display: inline-block;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      width: 90%;
      height: 100vh !important;
      max-height: 700px;
      filter: brightness(30%) grayscale(30%);
    }
    .headingTextTravel {
      color: white;
      font-size: 60px;
      font-weight: 600;
      position: absolute;
      top: 0;
      left: 0;
      width:100%;    
      height:100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (max-width: 800px) {
    .galleryHeading {
      .headingImage {
        height: auto !important;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .galleryHeading {
      .headingTextTravel {
        font-size: 38px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .galleryHeading {
      position: relative;
      .headingImage {
        position: relative;
        display: inline-block;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        border-radius: 0px;
        width: 100%;
        height: auto !important;
        filter: brightness(30%) grayscale(30%);
      }
    }
  }
  #photos {
    /* Prevent vertical gaps */
    line-height: 0;
    column-gap: 0;
    width: 100%;
    margin: auto;
    overflow: hidden;
    column-count: 3;

    @media (max-width: 1200px) {
      column-count: 4;
    }
  
    @media (max-width: 1000px) {
      column-count: 3;
    }
  
    @media (max-width: 800px) {
      column-count: 2;
    }
  
    @media (max-width: 400px) {
      column-count: 2;
    }
  
    @media (max-width: 350px) {
      column-count: 1;
    }
  }
  
  #photos .image-main-gallery {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px; /* Adjust the margin as per your requirements */
  
    &:hover {
      filter: brightness(0.8);
    }
  }
  
 
.imageContainer.skeleton {
  background-color: #f2f2f2;
}

.imageContainer.skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #f2f2f2 0%, #e6e6e6 20%, #f2f2f2 40%, #f2f2f2 100%);
  background-repeat: no-repeat;
  background-size: 200% 100%;
  animation: shimmer 1.5s linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}


 .image-container {
   position: relative;
   .image-details {
      position: absolute;
      bottom: 15px;
      text-align: left;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      .user {
        .profile-picture-user {
          height: 50px;
          width: 50px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .information-image-user {
        width: 100%;
        display: flex;
        flex-direction: column !important;
        gap: 1.2rem;
        .username {
          font-size: 14px;
          font-weight: 600;
        }
        .location {
          width: 100%;
          font-size: 10px;
          text-stroke: 1px white;
          -webkit-text-stroke: 1px white; /* For Safari */
          color: transparent;
        }
        
      }
   }
 }

  .gallery {
    .imageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    
        .img-c {
            width: 100%;
        }
    
        .imageMain {
            width: 100%;
            height: auto;
        }
    
        // styles for horizontal images
        .imageMain[data-aspect-ratio="landscape"] {
            width: calc(33.33% - 20px);
        }
    
        // styles for vertical images
        .imageMain[data-aspect-ratio="portrait"] {
            width: calc(50% - 20px);
        }
    
        // styles for square images
        .imageMain[data-aspect-ratio="square"] {
            width: calc(25% - 20px);
        }
    
        .imageOverlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
    
            .cameraIcon {
                font-size: 40px;
                margin-bottom: 10px;
            }
    
            .mobileShow {
                display: none;
            }
        }
    }
    

  
  }
  
  .navTopGallery {
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    margin-bottom: 50px;
    z-index: 1000;
  }
  .stateToggle {
    margin-top: -60px;
    width: 150px;
    margin-left: auto;
    margin-right: 60px;
    margin-bottom: 20px;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 10001;
    color: darkgray;
    font-size: 20px;
    transition: ease-in-out .3s;
    &:hover {
        color: black;
    }
  }
  
  .stateToggle .changeImages {
    color: white;
    background: #13678A;
    border: 1px solid #13678A;
    border-radius: 3px;
    bottom: 96.7%;
    font-size: 14px;
    font-weight: 600;
    display: block;
    opacity: 0;
    padding: 15px;
    pointer-events: none;
    margin-left:-3px;
    position: absolute;
    width: 100px;
    height: 45px;
    z-index: 1102;
    -webkit-transform: translateY(10px);
       -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
         -o-transform: translateY(10px);
            transform: translateY(10px);
    -webkit-transition: all .7s ease-out;
       -moz-transition: all .7s ease-out;
        -ms-transition: all .7s ease-out;
         -o-transition: all .7s ease-out;
            transition: all .7s ease-out;
  }
  .stateToggle .changeImageGallery {
    color: white;
    background: #13678A;
    border: 1px solid #13678A;
    border-radius: 3px;
    bottom: 87%;
    font-size: 12px;
    font-weight: 600;
    display: block;
    opacity: 0;
    padding: 15px;
    pointer-events: none;
    margin-left:-3px;
    position: absolute;
    width: 100px;
    height: 45px;
    z-index: 1102;
    -webkit-transform: translateY(10px);
       -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
         -o-transform: translateY(10px);
            transform: translateY(10px);
    -webkit-transition: all .7s ease-out;
       -moz-transition: all .7s ease-out;
        -ms-transition: all .7s ease-out;
         -o-transition: all .7s ease-out;
            transition: all .7s ease-out;
  }
  .stateToggle .changeImageGallery:before {
    bottom: -42px;
    content: " ";
    display: block;
    height: 20px;
    left: 161px;
    position: absolute;
  }
  
  /* Yellow triangle */
  .stateToggle .changeImageGallery:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #13678A 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
  }
  
  
  .stateToggle:hover .changeImageGallery {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
  }
  
  /* IE can just show/hide with no transition */
  .lte8 .stateToggle .changeImageGallery {
    display: none;
  }
  
  .lte8 .stateToggle:hover .changeImageGallery {
    display: block;
  }
  
  /* This is the grey triangle behind the yellow one that makes the border continuous */
  .stateToggle .changeImages:before {
    bottom: -42px;
    content: " ";
    display: block;
    height: 20px;
    left: 161px;
    position: absolute;
  }
  
  
  /* Yellow triangle */
  .stateToggle .changeImages:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #13678A 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
  }
  
  #dark {
    border-radius: 10px;
  }
  
  .stateToggle:hover .changeImages {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
  }
  
  /* IE can just show/hide with no transition */
  .lte8 .stateToggle .changeImages {
    display: none;
  }
  
  .lte8 .stateToggle:hover .changeImages {
    display: block;
  }
  
  
  @media screen and (max-width: 780px) {
    .stateToggle .changeImages {
      display: none;
    }
    .stateToggle .changeImageGallery {
      display: none;
    }

  }
  
  .gallery:hover > div:hover {
    opacity: 1.0;
  
  }
  
  
  
  
  
  
  
  .modal {
    cursor: default;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .modal-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%); /* IE 9 */
        -webkit-transform: translate(-50%, -50%); /* Safari */
        margin: auto;
        width: fit-content;
        .modal-content {
            max-width: 75vw;
            max-height: 75vh;
        }
        .description {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
            color: white;
            gap: 2rem;
            font-weight: 600;
            font-size: 2vmax !important;
            max-width: 75vw;
            @media (max-width: 500px) {
                font-size: 1.5vmax !important;
            }
        }
         
        .description > div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            @media (max-width: 500px) {
              gap: .5rem;
          }
        }
    }
    
    .slideNavigation {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      font-family: 'Varela', sans-serif;
      color: white;
      position: fixed;
      bottom: 50px;
      width: 100%;
      .rightSlide {
        cursor: pointer;
        flex: 3;
        display: inline-block;
        .moveDirection {
          display: block;
        }
        transition: ease-in-out .2s;
        &:hover {
          font-weight: 600;
          color: #3D70F4;
          .slideIcon {
            color: #3D70F4;
          }
        }
      }
      .leftSlide {
        cursor: pointer;
        display: inline-block;
        flex: 3;
        .moveDirection {
          display: block;
        }
        transition: ease-in-out .2s;
        &:hover {
          font-weight: 600;
          color: #3D70F4;
          .slideIcon {
            color: #3D70F4;
          }
        }
      }
      .slideIcon {
          font-size: 20px;
          color: white;
          transition: ease-in-out .2s;
      }
      
  }
  
  
  /* Add Animation */
  .modal-content {  
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.5s;
    animation-name: fadein;
    animation-duration: 0.5s;
  }
  
  }
  

  
  
  @keyframes fadein {
      from {
          opacity:0;
      }
      to {
          opacity:1;
      }
  }
  
  @keyframes pop-swirl {
    0% {
      transform: scale(0) rotate(360deg);
    }
    60% {
      transform: scale(0.8) rotate(-10deg);
    }
    100% {
      transform: scale(1) rotate(0deg);
    }
  }
  
  /* The Close Button */
  .close {
    position: absolute;
    top: 30px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  .closeloader {
    position: fixed;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    left: 96%;
    cursor: pointer;
    &:hover,
    &:focus {
      color: #bbb;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  

  
  .loading {
    display: none;
    height: 100%;
    z-index: 9999;
    position: sticky;
  }
  
  
  #scroll-container {
    overflow: hidden;
    font-size: 20px;
  }
  
  #scroll-text {
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    
    -moz-animation: my-animation 15s linear infinite;
    -webkit-animation: my-animation 15s linear infinite;
    animation: my-animation 15s linear infinite;
  }
  
  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateX(100%); }
    to { -moz-transform: translateX(-100%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(100%); }
    to { -webkit-transform: translateX(-100%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
  
  .load {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    background: rgba(150, 147, 147, 0.5);
    overflow: hidden;
  }
  .loader {
    overflow: hidden;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 50px;
    height: 50px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    z-index: 1000;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    overflow: hidden;
  }
  .loadingtext {
    position: fixed;
    top: 60%;
    left: 52%;
    margin: auto;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    z-index: 1000;
    font-weight: 600;
    font-size: 20px;
    color: black;
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  
  .categories {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 10px;
    width: 90%;
    margin: auto;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    margin-bottom: -50px;
    .locationName {
      border: solid 3px #13678A;
      border-radius: 10px;
      font-weight: 600;
      font-size: 35px;
      cursor: pointer;
      &:hover {
        background-color: lightgray;
      }
    }
  }
  
 
  @media screen and (max-width: 450px) {
    


    .filterModes {
      display: flex;
      flex: 3;
      margin-left: 5%;
      margin-top: 10px;
      margin-bottom: 50px;
      padding-right: 3rem !important;
     select {
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        background-image: url("../../assets//filter-solid.svg");
        background-repeat: no-repeat;
        background-position-x: 5%;
        background-position-y: 50%;
        background-size: 30px 12px;
        padding-left: 3rem !important; 
    }
  
      .modeFilterSelect {
        font-size: auto;
        color: black;        
        .filterMode {
          text-align: center;
          width: 150px;
          cursor: pointer;
          padding: 10px;
          border-radius: 15px;
          box-shadow: 0 8px 10px -8px black;
          background-color: white;
          font-family: 'Varela', sans-serif;
          transition: ease-in-out .2s;
          &:focus {
            outline: none;
          }
          &:hover {
            background-color: lightgray;
          }
        }
        .selectHeader {
          content: "&xf0dc;";
          padding-left: 25px;
        }
      }
    }
      .countrySelect {
        font-size: 10px !important;
        color: black;
        flex-direction: row;
        margin-left: 20px;
        display: block;
        justify-content: center;
        align-items: center;
        margin: auto;
          .selectCountry {
            width: 150px;
            font-family: 'Varela', sans-serif;
            cursor: pointer;
            background-color: white;
            border-radius: 15px;
            box-shadow: 0 8px 10px -8px black;
            background-color: white;
            font-family: 'Varela', sans-serif;
            font-size: 15px !important;
            transition: ease-in-out .2s;
            text-align: center;
            &:focus {
              outline: none;
            }
            &:hover {
              background-color: lightgray;
            }

          }
      }
    
    .changeState {
      display: none;
    }
    .modeName {
      display: none;
    }
    
    

    .categories {
      font-size: 15px;
      .locationName {
        border-radius: 10px;
        font-weight: 600;
        font-size: 20px;
        cursor: pointer;
        &:hover {
          background-color: lightgray;
        }
      }
    }
    .viewMobile {
      display: block !important;
      visibility: visible !important;
      margin-top: -10px;
      margin-bottom: 10px;
      font-family: 'Varela', sans-serif;
      .phoneSymbol {
        color: #3D70F4;
        font-size: 20px;
      }
    }
    
    
  
  }
  
  @media screen and (max-width: 300px) {
    .categories {
      margin-bottom: 50px;
      font-size: 15px;
      .locationName {
        border: solid 3px black;
        border-radius: 10px;
        font-weight: 600;
        font-size: 10px;
        cursor: pointer;
        &:hover {
          background-color: lightgray;
        }
      }
    }
  }
    
  .captionToggle {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1 !important;
    bottom: 10px;
    right: 0px;
    overflow: hidden;
    z-index: 999;
    font-weight: 600;
    color: lightgray;
    @media (max-width: 450px) {
      bottom: 15px;
    }
  }
  .resetAll {
    display: inline-block;
    position: fixed;
    z-index: 1 !important;
    bottom: 25px;
    right: 0;
    margin-bottom: 30px;
    margin-right: 1rem;
    overflow: auto;
    .resetText {
      font-family: 'Varela', sans-serif;
      display: block;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      color: darkgray;
      font-weight: 400;
      margin-bottom: 20px;
      cursor: pointer;
      transition: ease-in-out .2s;
      font-size: 15px;
      z-index: 1 !important;

    }
    .resetIcon {
      color: white;
    }
    .resetImages {
      display: inline-block;
      margin-right: 0px;
      width: 50px;
      height: 40px;
      border-radius: 10px;
      background-color: #eb4034;
      font-weight: 600;
      transition: .3s ease-in-out;
      z-index: 1 !important;
      &:hover {
        background-color: #a32a22;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .changeState {
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 430px) {
    .toggleIcon {
      font-size: 15px;
      margin-left: 99%;
      margin-bottom: 150px;
    }
    .changeState {
      margin-top: 20px;
      margin-right: 5px;
      z-index: 1800;
      .toggleIcon {
        font-size: 15px;
      }
    }
    
    .loadingtext {
      position: fixed;
      top: 60%;
      left: 55%;
      margin: auto;
      transform: translate(-50%, -50%);
      transform: -webkit-translate(-50%, -50%);
      transform: -moz-translate(-50%, -50%);
      transform: -ms-translate(-50%, -50%);
      z-index: 1000;
      font-weight: 600;
      font-size: 15px;
      color: black;
    }
    .categories {
      margin-bottom: 20px;
      margin-top: -10px;
    }
  }
  
  @media screen and (max-width: 300px) {
    .toggleIcon {
      font-size: 15px;
    }
    .changeState {
      margin-top: 20px;
      margin-bottom: -20px;
      margin-right: -12px;
    }
  }
  @media screen and (max-width: 300px) {
    .toggleIcon {
      font-size: 15px;
      margin-left: 99%;
      margin-bottom: 150px;
    }
    .stateToggle {
      .changeState {
        margin-top: 20px;
        margin-bottom: -50px;
        width: 20%;
        font-size: 5px;
      }
    }
  
    .resetAll {
      left: 96%;
      margin-top: -90px;
      margin-bottom: 0px;
      display: flex;
      justify-content: right;
      margin-right: -12px;
      position: -webkit-sticky;
      position: sticky;
      top: 4rem;
      z-index: 999;
      .resetIcon {
        background-color: transparent;
        color: black;
        &:hover {
          background-color: transparent;
          color: darkgray;
        }
      }
      .resetImages {
        width: 25%;
        height: 20px;
        margin-bottom: 0px;
        border-radius: 0px;
        background-color: transparent;
        font-weight: 600;
        font-size: 10px;
        transition: .3s ease-in-out;
        &:hover {
          background-color: transparent;
          color: darkgray;
        }
      }
    }
    .loadingtext {
      position: fixed;
      top: 60%;
      left: 55%;
      margin: auto;
      transform: translate(-50%, -50%);
      transform: -webkit-translate(-50%, -50%);
      transform: -moz-translate(-50%, -50%);
      transform: -ms-translate(-50%, -50%);
      z-index: 1000;
      font-weight: 600;
      font-size: 15px;
      color: black;
    }
  }
  .imageContainer {
    position: relative;
    transition: .5s ease;
    overflow: hidden;
    cursor: pointer;
    .img-c {
      transition: .5s ease;
    }
    .imageOverlay:hover {
        filter: grayscale(.5);
    }
    .imageOverlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 30px;
      font-weight: 600;
      display: none;
      transition: .5s ease;
      cursor: pointer;
      z-index: 2000;
      width: 80%;
    }
  }
  .filterContainer {
    position: relative;
    transition: .5s ease;
    overflow: hidden;
    border-radius: 0px;
    .img-c {
      transition: .5s ease;
    }
    .imageOverlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 30px;
      font-weight: 600;
      display: none;
      transition: .5s ease;
      cursor: pointer;
      z-index: 100;
      width: 80%;
    }
    .vertical {
      max-height: 400px;
    }
  }
  .imageContainer:hover {
    .img-c:hover {
        filter: grayscale(0.3) brightness(0.7);
    }
  }
  
  .single {
    .filterContainer {
      .vertical {
        width: 300px;
        height: 200px;
      }
    }
  }

  .img-c {
    position: relative;
    .text-overlay {
      font-weight: 600;
      position: absolute;
      bottom: 0px;
      left: 0px;
      font-size: 1.5vmax;
      width: 100%;
      padding: 6%;
      background: white;
        color: black;
        mix-blend-mode: screen;
      .location {
        text-align: center;
        bottom: 0;
        left: 0;
        padding: 1%;
        font-size: 25px;
        @media screen and (max-width: 600px) {
          font-size: 15px;
        }
        font-weight: 600;
        width: 100%;
        color: white;
        mix-blend-mode: difference; /* this property makes the text transparent and blends it with the background image */
      }
    }
  }
  
  .filterContainer {
    position: relative;
    transition: .5s ease;
    overflow: hidden;
    margin-bottom: 20px;
    .img-c {
      transition: .5s ease;
      max-height: 280px;
      max-width: 1000px;
      margin-bottom: 120px;
    }
    .filterOverlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 30px;
      font-weight: 600;
      display: none;
      transition: .5s ease;
      cursor: pointer;
      z-index: 200;
      transition: transform cubic-bezier(0.4, 0, 0.2, 1) 350ms;
    }
  }

  .cameraIcon {
    margin-right: 10px;
  }
  @media screen and (max-width: 1200px) {
    .loadingtext {
      display: hidden;
    }
 
    .imageContainer {
      .imageOverlay {
        font-size: 24px;
        .cameraIcon {
          font-size: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 850px) {
    .loadingtext {
      display: hidden;
    }
    .captionDescription {
      #caption, #city, .iconLocation {
        font-size: 12px;
      }
    }
    .imageContainer {
      .imageOverlay {
        font-size: 20px;
        .cameraIcon {
          font-size: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .loadingtext {
      display: hidden;
    }
    .captionDescription {
      #caption, #city, .iconLocation {
        font-size: 12px;
      }
    }
    .imageContainer {
      .imageOverlay {
        font-size: 10px;
        .cameraIcon {
          font-size: 18px;
        }
      }
    }
  }
  
  @media screen and (max-width: 700px) {
    .captionDescription {
      #caption, #city, .iconLocation {
        font-size: 12px;
      }
    }
    .imageContainer {
      .imageOverlay {
        font-size: 15px;
        .cameraIcon {
          font-size: 20px;
        }
      }
    }
    .filterContainer {
      .filterOverlay {
        font-size: 15px;
      }
    }
  }
  
  @media screen and (min-width: 100px) {
    .captionDescription {
      #caption, #city, .iconLocation {
        font-size: 13px;
      }
    }
  }
  
  
  .filterModes {
    display: flex;
    flex: 3;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 50px;
   select {
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      background-image: url("../../assets//filter-solid.svg");
      background-repeat: no-repeat;
      background-position-x: 5%;
      background-position-y: 50%;
      background-size: 30px 12px;
  }

    .modeFilterSelect {
      font-size: auto;
      color: black;
      .filterMode {
        text-align: center;
        width: 150px;
        cursor: pointer;
        padding: 10px;
        border-radius: 15px;
        box-shadow: 0 8px 10px -8px black;
        background-color: white;
        font-family: 'Varela', sans-serif;
        transition: ease-in-out .2s;
        padding-right: 5px;
        &:focus {
          outline: none;
        }
        &:hover {
          background-color: lightgray;
        }
      }
      .selectHeader {
        content: "&xf0dc;";
      }
    }
  }
    .countrySelect {
      font-size: 10px !important;
      color: black;
      flex-direction: row;
      margin-left: 0px;
        .selectCountry {
          padding-left: 25px !important;
          text-align: center;
          width: 150px;
          font-family: 'Varela', sans-serif;
          cursor: pointer;
          background-color: white;
          padding: 10px;
          border-radius: 15px;
          box-shadow: 0 8px 10px -8px black;
          background-color: white;
          font-family: 'Varela', sans-serif;
          font-size: 15px !important;
          transition: ease-in-out .2s;
          &:focus {
            outline: none;
          }
          &:hover {
            background-color: lightgray;
          }
        }
    }
  
    
    
    
  
  .searchBar {
    margin-left: 70%;
    align-items: right;
    justify-content:right;
    .searchInputImage {
      width: 150px;
    }
  
  }


  


.modeName {
  font-size: 10px;
  font-family: 'Varela', sans-serif;
  margin-top: -5px;
  cursor: pointer;
}
img {
  -webkit-user-select:none;
   -webkit-touch-callout:none;
}

.filterMethod {
  display: flex;
  margin-bottom: -50px;
  .showCollection {
      margin-left: 20px;
      .openCollections {
        padding: 10px;
        box-shadow: 0 8px 10px -8px black;
        background-color: white;
        border-radius: 15px;
        transition: ease-in-out .2s;
        &:hover {
          background-color: lightgray;
        }
      }
  }
}

@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.20s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;
  
  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;

    #{$vertical}: 0; 
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;

  }
  
  &:hover {
    color: $hover;
    
    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0s, 0s, $duration; }
    
    &::after { transition-delay: 0s, $duration, 0s; }
  }
}

.draw-border {
  @include btn-border-drawing(white, #3D70F4, 4px, bottom, right);
}

//=== Button styling, semi-ignore
.btn {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  padding: 1em 1em;
  font-weight: 600;
  letter-spacing: 0.05rem;
  
}
.loadMore {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.scrollTop {
  background-color: white;
  color: #3D70F4;
  padding: 20px 40px;
  font-size: 24px;
  //mobile query 
  @media screen and (max-width: 450px) {
    padding: 10px 20px;
    font-size: 15px;
  }
  font-weight: bold;
  border: 2px solid #3D70F4;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:hover {
    background-color: #3D70F4;
    color: white;
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(61, 112, 244, 0.4);
  }

  &:active {
    transform: translateY(1px);
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: -1;
  }

  &:hover::before {
    opacity: 1;
    transform: translateX(100%);
  }
}

.scrollTopPage {
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #3D70F4;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &:hover:before {
    transform: scaleX(1);
  }
}

.moreIcon {
  margin-left: 5px;
}

.hiddenImages {
  display: none;
}

#headingImage {
  object-position:initial;
}
.collectionBreaker {
  background-color: #3D70F4;
  width: 5%;
  margin: auto;
  border: none;
  height: 5px;
}


.mobileShow {
  display: none;
  visibility: hidden;
  font-size: 10px;
  .phoneIcon {
    color: #3D70F4;
    margin-right: 5px;
  }
}

.viewMobile {
  display: none;
  visibility: hidden;

  font-family: 'Varela', sans-serif;
  .phoneSymbol {
    color: #3D70F4;
    font-size: 20px;
  }
}


.load-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.load-more-button {
  background-color: white;
  color: #3D70F4;
  padding: 20px 40px;
  font-size: 24px;
  //mobile query
  @media screen and (max-width: 450px) {
    padding: 10px 30px;
    font-size: 15px;
  }
  font-weight: bold;
  border: 2px solid #3D70F4;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:hover {
    background-color: #3D70F4;
    color: white;
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(61, 112, 244, 0.4);
  }

  &:active {
    transform: translateY(1px);
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: -1;
  }

  &:hover::before {
    opacity: 1;
    transform: translateX(100%);
  }
}



.pagination-controls {
  display: flex;
  justify-content: space-between; /* Aligns children to the far edges */
  width: 90%; /* Takes up full width */
  padding: 10px 0; /* Adds vertical padding to the container */
  margin: auto;
  margin-top: 20px;
}

.prev-button,
.next-button {
  padding: 10px 20px; /* Adds padding to buttons */
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
}

.prev-button:disabled,
.next-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

