@mixin btn-border-drawing($color:#ccc, $hover: #3D70F4, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.20s) {
    box-shadow: inset 0 0 0 $width $color;
    color: $color;
    transition: color $duration $duration/3;
    position: relative;
    font-weight: 600;

    &::before,
    &::after {
      border: 0 solid transparent;
      box-sizing: border-box;
      content: '';
      pointer-events: none;
      position: absolute;
      width: 0; height: 0;
        font-weight: 600;
      #{$vertical}: 0; 
      #{$horizontal}: 0;
      font-weight: 600;
    }
  
    &::before {
      $h-side: if($horizontal == 'left', 'right', 'left');
      border-#{$vertical}-width: $width;
      border-#{$h-side}-width: $width;
      font-weight: 600;
    }
    
    &::after {
      $v-side: if($vertical == 'top', 'bottom', 'top');
      font-weight: 600;
      border-#{$v-side}-width: $width;
      border-#{$horizontal}-width: $width;
  
    }
    
    &:hover {
      color: $hover;
      font-weight: 600;

      &::before,
      &::after {
        font-weight: 600;
        border-color: $hover;
        transition: border-color 0s, width $duration, height $duration;
        width: 100%;
        height: 100%;
      }
      
      &::before { transition-delay: 0s, 0s, $duration; }
      
      &::after { transition-delay: 0s, $duration, 0s;        font-weight: 600;
      }
    }
  }

.header-wrapper {
    z-index: 9999;
    overflow: hidden;
    .header {
        position: relative;
        padding: 0;
        margin: 0;
        width: 100vw !important;
        height: 100vh;
        overflow: hidden;
        .header-image {
            width: 100vw !important;
            height: 100vh !important;
            object-fit: cover !important;
            -webkit-filter: brightness(.3) blur(.5px) contrast(1.2); //change the value according to what suits the best
            -moz-filter: brightness(.3) blur(.5px) contrast(1.2); //change the value according to what suits the best
            -o-filter: brightness(.3) blur(.5px) contrast(1.2); //change the value according to what suits the best
            -ms-filter: brightness(.3) blur(.5px) contrast(1.2); //change the value according to what suits the best
            filter: brightness(.5) blur(.5px) contrast(1.2); //change the value according to what suits the best
          }
        .header-contents {
            position: absolute;
            top: 50%;
            left: 30%;
            transform: translate(-50%, -50%);
            color: white;
            display: flex;
            flex-direction: column;
            @media (max-width: 550px) {
              left: 40%;
              .name {
                text-align: left;
                font-size: 4vmax !important;
                @media (max-width: 460px) {
                  font-size: 5.5vmax !important;
                }
                width: 100% !important;
              }
            }
            .name {
                font-family: 'Allura', cursive;
                font-size: 7vmax;
                display: block;
                overflow: hidden !important;
            }
            .name-false {
                font-family: 'Allura', cursive;
                font-size: 4vmax;
                display: block;
                overflow: hidden !important;
                @media (max-width: 460px) {
                  font-size: 5.5vmax !important;
                }
                padding: 0 40px 0px 10px;
            }
            .information {
                font-size: 20px;
                text-align: left;
                font-family: 'Raleway', sans-serif;
                display: block;
                overflow: hidden !important;
            }
            .scroll-button-wrapper {
                padding-top: 2rem;
            }
            .scroll-button {
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60%;
                height: 5vmax;
                max-height: 70px;
                font-weight: 600;
                text-align: center;
                @include btn-border-drawing(white, #3D70F4, 4px, bottom, right);
                @media (max-width: 600px) {
                  width: 80%;
                  height: 30%;
                  font-size: 15px;
                }
            }
            .move-left {
              margin-left: 30px;
            }
            
            .down-arrow {
                padding-left: .5rem;
            }
        }
    }
}




.name, .header-contents {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}