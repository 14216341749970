

.footer {
    font-family: 'Varela', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    font-size: 11px;
    width: 100%;
    height: 100%;
    // put horizontal line between footer and content
    border-top: 1px solid #ddd;
    overflow-y: hidden;
    background-color: #007bff;
    color: white;
    @media screen and (max-width: 450px) {
        padding-bottom: 1rem;
    }
    .centeredText {
        display: flex;
        justify-content: space-between; /* This will push items to the edges */
        align-items: center;
        width: 100%; /* Make sure this container takes up full width */
        max-width: 1200px; /* You can adjust this value as needed */
        margin-top: 1rem;
        @media screen and (max-width: 450px) {
            flex-direction: column;
            gap: 1rem;
        }
        .contact-button {
            order: -1; /* Move the contact button to the left */
            @media screen and (max-width: 450px) {
                order: 1; /* Move the contact button to the right */
            }
            flex-shrink: 0; /* Prevent this from shrinking */
            display: flex;
            flex-direction: row;
            gap: 1rem;
            .contact-btn {
                background-color: white;
                color: #fff;
                border: none;
                padding: 10px 10px;
                cursor: pointer;
                font-size: 16px;
                border-radius: 5px;
                transition: ease-in-out 0.2s; 
                color: #007bff;
                &:hover {
                    background-color: #0056b3;
                }               
            }
            .context {
                font-size: 10px;
                font-weight: 600;
                padding-top: .25rem;
                padding-bottom: .5rem;
            }
        }
    
        .footerTextMain {
            font-size: 20px;
            font-weight: 600;
            flex-grow: 1; /* Allow this to grow and take up available space */
            text-align: center; /* Center the text */
            display: flex;
            flex-direction: row;
            gap: .5rem;
            justify-content: center;
            align-items: center;
            .cursive-footer {
                font-size: 30px;
                font-family: "Allura", cursive !important;
            }
            @media screen and (max-width: 450px) {
                font-size: 15px;
                .cursive-footer {
                    font-size: 25px;
                    font-family: "Allura", cursive !important;
                }
            }
        }
    }
    
}