.writeForm {
    box-sizing: content-box;
}
.imageHeader {
    position: relative;
    .header {
      width: auto;
      position: relative;
      display: inline-block;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      filter: brightness(30%) grayscale(30%);
      margin-bottom: 30px;
    }
    .headingTextTravel {
      color: white;
      font-size: 60px;
      font-weight: 600;
      position: absolute;
      top: 0;
      left: 0;
      width:100%;    
      height:100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

.writeInput {
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px;
    width: 30%;
    box-sizing: content-box;
}
.submitWrite {
    padding:5px 15px; 
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px;
    width: 30%;
    cursor:pointer;
    -webkit-border-radius: 5px;
    font-weight: 600;
    transition: ease-in-out .3s;
    &:hover {
        background-color: lightgray;
    }
}
textarea {
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px;
    box-sizing: content-box;
    width: 30%;
    margin-bottom: 10px;
}
#success {
    display: none;
}
.successDiv {
    .checkMark {
        margin-right: 10px;
    }
    margin-top: 20px;
    font-size: 20px;
    font-family: 'Overpass', sans-serif;
}
.enterContent {
    height: 200px;
}
.viewPost {
    transition: ease-in-out .2s;
    &:hover {
        color: darkgray;
    }
    font-family: 'Overpass', sans-serif;
}
@media screen and (max-width: 700px) {
    .imageHeader {
        .header {
            width: 500px;
        }
        .headingTextTravel {
            font-size: 35px;
        } 
    }
}
@media screen and (max-width: 430px) {
    .imageHeader {
        .header {
            width: 80%;
        }
        .headingTextTravel {
            font-size: 28px;
        } 
    }
}