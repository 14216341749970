
.centerLogin {
  margin-top: 3rem;
}
.login {
    color: black;
    margin-top: auto;
    width: 100%;
}
  
  .login-form {
    text-align: left !important;
  }
  
  .centered img {
    width: 150px;
    border-radius: 50%;
  }
.email {
    width: 100%;
    height: 50%;
}
.loginForm {
    text-align: left;
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    justify-content: center;
    align-items: center;
}
.detail {
    font-family: 'Varela', sans-serif;
    font-size: 12px;
    text-align-last: left;
    margin-top: 5px;
}
input {
    box-sizing: content-box;
    font-family: 'Varela', sans-serif;
    font-size: 12px;
}
.submitButton {
    color: white;
    margin-top: 20px;
    width: 93%;
    box-sizing: content-box;
    font-family: 'Varela', sans-serif;
    font-weight: 600;
    cursor: pointer;
    background-color: #3D70F4;
    transition: ease-in-out .3s;
    &:hover {
        background-color: #0e59b5;
    }
    border: none;
}

.welcomeHeader {
    .welcome {
        font-size: 30px;
        font-family: 'Varela', sans-serif;
        font-weight: 600;
    }
    .welcomeSub {
        font-size: 10px;
        color: darkgray;
        font-family: 'Varela', sans-serif;
        display: inline-block;
        margin-bottom: 10px;
        text-align: center;
    }
}
@media screen and (max-width: 1000px) {
    .split {
        height: 80%;
        width: 50%;
        position: fixed;
        margin-top: 0%;
        z-index: 1;
        top: 100;
        overflow-x: hidden;
        overflow-y: hidden;
        padding-top: 20px;
      }
      .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -30%);
      }
    .left {
        display: none;
    }
    .right {
        width: 100%;
    }
}
@media screen and (max-width: 700px) {
    .split {
        height: 100%;
        width: 50%;
        position: fixed;
        margin-top: 0%;
        z-index: 1;
        top: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        padding-top: 20px;
      }
      .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -30%);
      }
    .left {
        display: none;
    }
    .right {
        width: 100%;
    }
}
.signUpSection {
  box-sizing: content-box;
  margin: auto;
  text-align: center;
  vertical-align: text-top;
  margin-top: 10px;
  margin-left: 15px;
  .signUpText {
    .signUp {
      text-align: center;
      color: #3D70F4;
      transition: ease-in-out .3s;
      &:hover {
          color: darkblue;
      }
    }
    .signUpDetail {
      color: darkgray;
    }
    font-family: 'Varela', sans-serif;
    font-size: 10px;
  }
}


.maininput {
  width: 300px;
}

.formWrapper {
  margin: auto;
  display: block;
  justify-content: center;
  align-items: center;
}




.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }