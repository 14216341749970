.loader-spinner {
    //make it take the full page and have an opaque light black background
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    //center the spinner
    display: flex;
    justify-content: center;
    align-items: center;
    //prevent scroll
    overflow: hidden;
    
}