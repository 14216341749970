.contact {
    background-color: white !important;
    
}

.header-contact {
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    color: #3D70F4;
}

.subtitle-contact {
    color: darkgray;
}

.form-contents {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 80%;
    justify-content: left;
    padding-top: 1rem;
    label {
        color: black;
        margin-bottom: -.5rem;
    }
    input {
        padding: 1rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
    }
    textarea {
        padding: 1rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        height: 100px;
    }    
    
}

.submit-button-contact {
    background-color: white;
  color: #3D70F4;
  padding: 10px 20px;
  font-size: 24px;
  width: 100% !important;
  //mobile query
  @media screen and (max-width: 450px) {
    padding: 20px 20px;
    font-size: 20px;
    margin-top: .5rem;
  }
  font-weight: bold;
  border: 2px solid #3D70F4;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:hover {
    background-color: #3D70F4;
    color: white;
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(61, 112, 244, 0.4);
  }

  &:active {
    transform: translateY(1px);
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: -1;
  }

  &:hover::before {
    opacity: 1;
    transform: translateX(100%);
  }
}

.submission-status {
    color: #3D70F4;
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
    padding-top: 1rem;
}